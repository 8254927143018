import { EmailSentStatus } from "api/external-manufacturing/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";

interface Props {
  status: EmailSentStatus;
}

const emailSendingStatusMap: Record<
  EmailSentStatus,
  { label: string; variant: TagProps["variant"] }
> = {
  NOT_SENT: { label: "niewysłane", variant: "warning" },
  SENT: { label: "wysłane", variant: "info" },
  DELIVERED: { label: "dostarczone", variant: "deepPurple400" },
  SEEN: { label: "odczytane", variant: "success" },
  ERROR: { label: "błąd wysyłki", variant: "danger" },
};

export const EmailSendingStatus = ({ status }: Props) => {
  return (
    <Tag
      label={emailSendingStatusMap[status].label}
      variant={emailSendingStatusMap[status].variant}
    />
  );
};
