import { warehouseActions } from "api/warehouse/actions";
import { getPackagesQuery } from "api/warehouse/calls";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Formik, FormikHelpers } from "formik";
import { useParams } from "react-router";
import { cx } from "utilities";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";

export interface InitialValues {
  index: number;
  package: number | null;
}

interface Props {
  setIsAddingPackage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddPackage = ({ setIsAddingPackage }: Props) => {
  const { indexId } = useParams<{ indexId: string }>();

  const initialValues: InitialValues = {
    index: Number(indexId),
    package: null,
  };

  const postMutation = warehouseActions.useAddPackageToIndex(setIsAddingPackage);

  const handleSubmit = (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    postMutation.mutate({ ...values, package: values.package! }, handleSubmitResponse(actions));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
          <div className="mx-3">
            <div className="mb-2">
              <Search.Form fetcherFn={getPackagesQuery} isNullable name="package" />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 px-3 py-2">
            <Button
              className="text-uppercase"
              onClick={() => setIsAddingPackage(false)}
              size="medium"
              variant="transparent"
            >
              Anuluj
            </Button>
            <Button
              className="text-uppercase"
              disabled={!values.package}
              isLoading={isSubmitting}
              size="medium"
              type="submit"
              variant="deepPurple"
            >
              Dodaj
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
