import * as React from "react";
import { queryString } from "utilities";
import { useHistory, useLocation } from "react-router-dom";

export type OutputQuery = Record<string, string>;
export interface InputQuery {
  [key: string]: string | boolean | number;
}
export interface Value {
  query: OutputQuery;
  setQuery: (arg: InputQuery, stringifyOptions?: { allowEmpty?: boolean }) => void;
  updateQuery: (arg: InputQuery) => void;
  search: string;
}

export const queryContext = React.createContext<Value>({
  query: {},
  setQuery: q => {},
  updateQuery: q => {},
  search: "",
});

export const QueryProvider: React.FC<{}> = ({ children }) => {
  const history = useHistory();
  const { search } = useLocation();

  const setQuery = React.useCallback(
    (q: InputQuery, stringifyOptions) => {
      history.replace({ search: queryString.stringify(q, stringifyOptions) });
    },
    [history],
  );
  const query = React.useMemo(() => queryString.parse(search), [search]);

  /**
   * Be aware that updateQuery doesn't work well with asynchronous methods
   * like throttling or debouncing, because it has "old" version of query object
   * in its lexical scope. If you want to use it asynchronously, better use setQuery
   * and pass current query object saved in ref or class attribute.
   */
  const updateQuery = React.useCallback(
    (q: InputQuery) => {
      history.replace({ search: queryString.stringify({ ...query, ...q }) });
    },
    [history, query],
  );

  const value = React.useMemo(
    () => ({
      query,
      setQuery,
      updateQuery,
      search,
    }),
    [query, setQuery, search, updateQuery],
  );

  return <queryContext.Provider value={value}>{children}</queryContext.Provider>;
};
