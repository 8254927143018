import { useQuery } from "hooks";
import { FilterAsyncSearchType } from "../../types";
import { ApiFetcher, createApiQuery } from "hooks/createApiQuery";
import { Search } from "components/miloDesignSystem/molecules/search";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Assign } from "utility-types";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { OutputQuery } from "components/utils/queryProvider";
import { useQueryClient } from "react-query";
import { InferPaginationItem } from "typeUtilities";

export const FilterAsyncSearch = <
  TRes extends unknown,
  FetchFrom extends (arg: string) => ApiFetcher<TRes>,
  FormName extends string
>(
  props: FilterAsyncSearchType<FetchFrom, FormName>,
) => {
  const { multiple = true } = props;
  if (multiple) return <MultipleSearch<TRes, FetchFrom, FormName> {...props} />;

  return <SingleSearch<TRes, FetchFrom, FormName> {...props} />;
};

export const MultipleSearch = <
  TRes extends unknown,
  FetchFrom extends (arg: string) => ApiFetcher<TRes>,
  FormName extends string
>(
  props: FilterAsyncSearchType<FetchFrom, FormName>,
) => {
  const { name, fetchListFrom, placeholder } = props;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const values = getValues(query, name);

  const onChange = (item: InferPaginationItem<TRes>) => {
    assertIsDefined(item);
    const value = (item as Record<string, unknown>)[props.valueKey] as string | number;

    const isAlreadyAdded = values.includes(String(value));

    if (isAlreadyAdded) {
      updateQuery({
        [name]: values.filter((queryValueKey: string) => queryValueKey !== String(value)).join(","),
        page: 1,
      });
      return;
    }

    updateQuery({
      [name]: [...values, value].join(","),
      page: 1,
    });
  };

  return (
    <div>
      <Search<TRes>
        key={query[name]}
        fetcherFn={fetchListFrom}
        isNullable={false}
        theme="dark"
        selectedItem={null}
        textFieldProps={{ placeholder: placeholder ?? "Szukaj" }}
        onChange={onChange}
      />
      <div className="d-flex flex-wrap gap-2 mt-2">
        {values.map(value => (
          <FilterAsyncSearchDisplayItem<TRes, FetchFrom, FormName> id={value} {...props} />
        ))}
      </div>
    </div>
  );
};

export const FilterAsyncSearchDisplayItem = <
  TRes extends unknown,
  FetchFrom extends (arg: string) => ApiFetcher<TRes>,
  T extends string
>({
  fetchDetailsFrom,
  displayKey,
  id,
  name,
  label,
}: Assign<FilterAsyncSearchType<FetchFrom, T>, { id: string }>) => {
  const { updateQuery, query } = useQuery({ exclude: ["panelId"] });
  const useDetailsFetch = createApiQuery(fetchDetailsFrom);
  const { data, isLoading, error } = useDetailsFetch(id, { enabled: Boolean(id) });
  const values = getValues(query, name);
  if (isLoading) {
    return (
      <div className="mt-1">
        <Spinner size={18} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          updateQuery({
            [name]: values.filter((queryValueKey: string) => queryValueKey !== id).join(","),
            page: 1,
          });
        }}
      >
        <Tag label={label} variant="quaternary" theme="dark" endIcon={MdiClose} />
      </div>
    );
  }

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        updateQuery({
          [name]: values.filter((queryValueKey: string) => queryValueKey !== id).join(","),
          page: 1,
        });
      }}
    >
      <Tag label={data?.[displayKey]} variant="quaternary" theme="dark" endIcon={MdiClose} />
    </div>
  );
};

export const SingleSearch = <
  TRes extends unknown,
  FetchFrom extends (arg: string) => ApiFetcher<TRes>,
  FormName extends string
>({
  fetchListFrom,
  name,
  placeholder,
  fetchDetailsFrom,
  valueKey,
  displayKey,
}: FilterAsyncSearchType<FetchFrom, FormName>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const useDetailsFetch = createApiQuery(fetchDetailsFrom);
  const { data, isLoading } = useDetailsFetch(query[name], { enabled: Boolean(query[name]) });
  const queryClient = useQueryClient();

  const onChange = (item: InferPaginationItem<TRes> | null) => {
    const value = (item as Record<string, unknown>)[valueKey] as string | number;

    queryClient.setQueryData(fetchDetailsFrom(value).key, item);
    updateQuery({
      [name]: item ? value : "",
      page: 1,
    });
  };
  return (
    <Search
      fetcherFn={fetchListFrom}
      isNullable={true}
      theme="dark"
      isLoading={isLoading}
      textFieldProps={{ placeholder: placeholder ?? "Szukaj" }}
      selectedItem={data ? { text: data[displayKey], value: data[valueKey] } : null}
      onChange={onChange}
    />
  );
};

function getValues(query: OutputQuery, name: string) {
  if (!query[name]) {
    return [];
  }
  return query[name].split(",");
}
