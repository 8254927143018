import { PackageStockStatus } from "api/wms/models";
import { ReturnStatus } from "api/wms/returns/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const statusOptions: Record<ReturnStatus, { label: string; variant: TagProps["variant"] }> = {
  NOT_STARTED: { label: "nie rozpoczęto", variant: "outline" },
  IN_PROGRESS: { label: "w trakcie", variant: "info" },
  FINISHED: { label: "zakończono", variant: "success" },
};

const packageGroupStatusObject: Record<
  PackageStockStatus,
  { label: string; variant: TagProps["variant"] }
> = {
  IN_STOCK: { label: "przyjęte na magazyn", variant: "success" },
  NOT_IN_STOCK: { label: "nie zwrócono", variant: "outline" },
};

export const wmsReturnConstants = {
  packageGroupStatusObject,
  statusOptions,
};
