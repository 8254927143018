import { RouteLineItem, ScheduleEmailStatus } from "api/external-manufacturing/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { EmailConfirmationStatus } from "./components/EmailConfirmationStatus";
import { dateUtils } from "utilities";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { differenceInHours } from "date-fns";
import { PlannedPickupDate } from "./components/PlannedPickupDate";
import { RouteSimplified } from "api/logistics/routes/models";
import { Link } from "components/miloDesignSystem/atoms/link";
import { ISODateTime } from "api/types";

const scheduleEmailStatusDict: Record<
  ScheduleEmailStatus,
  { label: string; variant: TagProps["variant"] }
> = {
  NOT_SENT: { label: "Niewysłano", variant: "info" },
  SCHEDULED: { label: "Gotowe do wysyłki", variant: "quaternary" },
  SENT: { label: "Wysłano zapytanie", variant: "success" },
};

export const useRouteLineItemsColumns = (routeDetails: RouteSimplified | null) => {
  return useCreateTableColumns<RouteLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.order, {
        header: "zamówienie",
        size: 145,
        cell: info => {
          const order: RouteLineItem["order"] = info.getValue();
          if (!order) return <EmptyValue fontSize="14" />;
          return (
            <Link fontSize="16" fontWeight="700" to={`/orders/list/active/all?panelId=${order.id}`}>
              {order.signature}
            </Link>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 255,
        cell: info => {
          const lineItem: RouteLineItem = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="16" fontWeight="700">
                {lineItem.signature}
              </Typography>
              {!lineItem.scheduleEmailSentAt &&
                lineItem.scheduleEmailStatus === ScheduleEmailStatus.NOT_SENT && (
                  <Tag label="NOWO DODANE" type="filled" variant="info" />
                )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.manufacturer.name, {
        header: "producent",
        size: 140,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.product.name, {
        header: "nazwa",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row.product, {
        header: "atrybuty",
        size: 300,
        cell: info => {
          const product: RouteLineItem["product"] = info.getValue();
          if (!product || !Boolean(product.attributes.length)) return <EmptyValue />;
          return (
            <Attributes
              attributes={product.attributes.map(attribute => ({
                id: attribute.id,
                label: attribute.name,
                value: attribute.value,
              }))}
            />
          );
        },
      }),
      columnHelper.text(row => row.product.manufacturerName, {
        header: "nazwa producenta",
        size: 140,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "data odbioru",
        size: 128,
        cell: info => {
          const lineItem: RouteLineItem = info.getValue();
          return <PlannedPickupDate routeDetails={routeDetails} routeLineItem={lineItem} />;
        },
      }),
      columnHelper.accessor(row => row.scheduleEmailStatus, {
        header: "status wys. zapytania",
        size: 120,
        cell: info => {
          const status: RouteLineItem["scheduleEmailStatus"] = info.getValue();
          return (
            <Tag
              label={scheduleEmailStatusDict[status].label}
              variant={scheduleEmailStatusDict[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.scheduleEmailSentAt, {
        header: "wysłano",
        size: 120,
        cell: info => {
          const date: ISODateTime | null = info.getValue();
          const today = new Date();
          if (!date) return <EmptyValue />;
          if (differenceInHours(today, new Date(date)) <= 24)
            return (
              <Typography fontSize="14" fontWeight="400">
                {dateUtils.formatDateToDisplay(date)}, {dateUtils.formatDateToTime(date)}
              </Typography>
            );
          return (
            <Typography fontSize="14" fontWeight="400">
              {dateUtils.formatDateToDisplay(date)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status przygotowania",
        size: 180,
        cell: info => {
          const lineItem: RouteLineItem = info.getValue();
          return <EmailConfirmationStatus routeLineItem={lineItem} />;
        },
      }),
    ];
  });
};
