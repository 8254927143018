import { createPaginatedKey } from "api/keys";

export const routesKeys = {
  route: (id: string) => ["route", id],
  routes: (params?: string) => (params ? ["routes", params] : ["routes"]),
  simplifiedRoutes: createPaginatedKey("simplifiedRoutes"),
  routeMessages: (id: string) => ["routeMessages", id],
  routeEmails: (id: string) => ["routeEmails", id],
  receptionIndexes: (id: string) => ["receptionIndexes", id],
  receptionEntries: createPaginatedKey("receptionEntries"),
  rampLoadingStatus: (id: string) => ["rampLoadingStatus", id],
  pinToRouteHistory: createPaginatedKey("pinToRouteHistory"),
  routeDeliveryPointsLinks: (id: string) => ["routeDeliveryPointsLinks", id],
  routeSynchronize: (id: string) => ["routeSynchronize", id],
  manufacturingRoutes: createPaginatedKey("manufacturingRoutes"),
  manufacturingRoute: (routeId: string) => ["manufacturingRoute", routeId],
  manufacturingRoutePositions: (routeId: number) => ["manufacturingRoutePositions", routeId],
  routesForInvoicing: createPaginatedKey("routesForInvoicing"),
};
