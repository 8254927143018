import { EmployeeKindExtended } from "CONSTANTS";
import { MethodOfSettlement, Transaction } from "api/manufacturing/employees/models";
import { EmployedPlace } from "api/manufacturingNew/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const employeeKindOptions: Record<
  EmployeeKindExtended,
  {
    label: string;
    variant: TagProps["variant"];
  }
> = {
  CARPENTER: { label: "stolarz", variant: "success" },
  CUTTER: { label: "krojczy", variant: "danger" },
  PACKER: { label: "pakowacz", variant: "info" },
  SEAMSTRESS: { label: "krawcowa", variant: "warning" },
  UPHOLSTERER: { label: "tapicer", variant: "deepPurple400" },
  WAREHOUSEMAN: { label: "magazynier", variant: "deepPurple50" },
  FOAMER: { label: "piankujący", variant: "success" },
  MATTRESSER: { label: "materacowy", variant: "info" },
};

const employmentPlaceOptions: Record<
  EmployedPlace,
  {
    label: string;
    variant: TagProps["variant"];
  }
> = {
  EXTERNAL: { label: "u kontrahenta", variant: "warning" },
  INTERNAL: { label: "wewnętrznie", variant: "deepPurple400" },
};

const transactionOptions: Record<
  Transaction,
  {
    label: string;
    variant: TagProps["variant"];
  }
> = {
  ADVANCE_PAYMENT: { label: "zaliczka", variant: "deepPurple400" },
  WITHDRAWAL: { label: "wypłata", variant: "success" },
};

const methodOfSettlementDict: Record<MethodOfSettlement, string> = {
  FIXED_SALARY: "Stałe wynagrodzenie",
  PIECEWORK: "Akord",
};

export const manufacturingEmployeesConstants = {
  employeeKindOptions,
  employmentPlaceOptions,
  methodOfSettlementDict,
  transactionOptions,
};
