import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik, FormikHelpers } from "formik";
import { cx, dateFns } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import {
  MaterialListItem,
  PostOrderManufacturingMaterial,
} from "api/manufacturing/materials/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { manufacturingApi } from "api/manufacturing/api";
import { manufacturingActions } from "api/manufacturing/actions";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useOrderMaterialColumns } from "./useOrderMaterialColumns";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { assertIsDefined } from "utilities/assertIsDefined";
import { ISODate, UUID } from "api/types";
import { SearchNormalizedItem } from "components/miloDesignSystem/molecules/search/types";
import { OrderMaterialModal } from "../RawMaterialsList";

interface Props {
  orderMaterialModal: OrderMaterialModal;
}

export interface PostOrderManufacturingMaterialFromValues {
  defaultScheduledDeadline: boolean;
  delivery: number | null;
  areEmailLabelsRequired: boolean;
  orders: {
    id: UUID;
    name: MaterialListItem["name"];
    category: MaterialListItem["category"];
    unit: MaterialListItem["unit"];
    material: UUID;
    manufacturer: SearchNormalizedItem | null;
    quantity: string;
    scheduledDeadline: ISODate | null;
  }[];
}
export const OrderMaterial = ({ orderMaterialModal }: Props) => {
  const postMutation = manufacturingActions.usePostManufacturingOrders(orderMaterialModal.close);

  const columns = useOrderMaterialColumns();
  const initialValues: PostOrderManufacturingMaterialFromValues = {
    delivery: null,
    defaultScheduledDeadline: true,
    orders: orderMaterialModal.state!.materials.map(material => {
      const defaultManufacturer: SearchNormalizedItem | null = (() => {
        const mainProvider = material.providers.find(provider => provider.isMain);
        if (mainProvider) {
          return {
            text: mainProvider.manufacturer.name,
            value: String(mainProvider.manufacturer.id),
          };
        }

        if (material.providers.length) {
          return {
            text: material.providers[0].manufacturer.name,
            value: String(material.providers[0].manufacturer.id),
          };
        }

        return null;
      })();

      return {
        category: material.category,
        id: material.id,
        manufacturer: defaultManufacturer,
        material: material.id,
        name: material.name,
        quantity: "0",
        scheduledDeadline: material.averageDeliveryDays
          ? dateFns.format(dateFns.addDays(new Date(), material.averageDeliveryDays), "yyyy-MM-dd")
          : null,
        unit: material.unit,
      };
    }),
    areEmailLabelsRequired: false,
  };

  const handleSubmit = (
    values: PostOrderManufacturingMaterialFromValues,
    actions: FormikHelpers<PostOrderManufacturingMaterialFromValues>,
  ) => {
    const formattedValues: Omit<PostOrderManufacturingMaterial, "isAlreadyOrdered"> = {
      ...values,
      orders: values.orders.map(order => ({
        ...order,
        manufacturer: order.manufacturer!.value,
      })),
    };

    postMutation.mutate(formattedValues, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };
  return (
    <Modal
      close={orderMaterialModal.close}
      isOpen
      width={820}
      title={
        <Typography fontSize="20" fontWeight="700">
          Zamów materiały
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, setValues }) => {
          return (
            <>
              <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                <div className="mx-3">
                  <div className="mb-4">
                    <Search.Form
                      fetcherFn={manufacturingApi.getManufacturingOrdersDeliveries}
                      isNullable={false}
                      name="delivery"
                      textFieldProps={{ label: "Dostarczyć do", placeholder: "Wyszukaj" }}
                    />
                  </div>
                </div>
                <div className="mx-3">
                  <Table<PostOrderManufacturingMaterialFromValues["orders"][number]>
                    overrides={() => ({
                      table: { className: "overflow-visible" },
                      tableInnerWrapper: { className: "overflow-visible" },
                    })}
                    rows={initialValues.orders}
                    columns={columns}
                    error={null}
                    isLoading={false}
                    uiSchema={{
                      header: {
                        backgroundColor: "neutralWhite88",
                      },
                      cell: {
                        height: "36",
                      },
                    }}
                  />
                </div>
                <div className="mx-3 mb-5 mt-3">
                  <div className="">
                    <Checkbox.Form
                      name="areEmailLabelsRequired"
                      size="sm"
                      label="Dołącz etykiety do email"
                    />
                  </div>
                </div>
                <Typography
                  fontSize="12"
                  fontWeight="700"
                  color="neutralBlack64"
                  className="mb-2 text-uppercase mx-3"
                >
                  Termin dostawy
                </Typography>
                <div className="d-flex flex-column mx-3">
                  <div className="mb-2">
                    <Radio
                      checked={values.defaultScheduledDeadline}
                      label="Najszybciej jak to możliwe"
                      helperText="Następny dzień roboczy"
                      onClick={value => {
                        setFieldValue("defaultScheduledDeadline", value);
                      }}
                      size="sm"
                    />
                  </div>

                  <div className="mb-1">
                    <Radio
                      checked={!values.defaultScheduledDeadline}
                      label="Wybieram datę"
                      onClick={value => {
                        setFieldValue("defaultScheduledDeadline", !value);
                      }}
                      size="sm"
                    />
                  </div>
                  <div className="ml-4 mb-4">
                    <DatePicker
                      isNullable={false}
                      onChange={date => {
                        assertIsDefined(date);
                        setValues({
                          ...values,
                          orders: values.orders.map(order => ({
                            ...order,
                            scheduledDeadline: dateFns.format(date, "yyyy-MM-dd"),
                          })),
                        });
                      }}
                      value={values.orders[0].scheduledDeadline || ""}
                      disabled={values.defaultScheduledDeadline}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center gap-2 p-3">
                  <Button
                    className="text-uppercase"
                    onClick={orderMaterialModal.close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Zamów
                  </Button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
