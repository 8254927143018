import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { Order } from "api/orders/models";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { getOrdersQuery } from "api/orders/calls";
import { useNavigate } from "hooks/useNavigate";

interface Props {
  close: () => void;
}

interface FormState {
  baseOrderId: Order["id"] | null;
  sourceOrdersIds: Order["id"] | null;
}

const initialValues: FormState = {
  baseOrderId: null,
  sourceOrdersIds: null,
};

export const AddPackageExchangeModal = ({ close }: Props) => {
  const postMutation = orderPackageExchangeActions.usePostOrderPackagesExchange();

  const navigate = useNavigate();

  const handleSubmit = (values: FormState, actions: FormikHelpers<FormState>) => {
    postMutation.mutate(
      {
        baseOrderId: values.baseOrderId!,
        sourceOrdersIds: values.sourceOrdersIds ? [values.sourceOrdersIds] : [],
      },
      {
        ...handleSubmitResponse(actions),
        onSuccess: payload => {
          handleSubmitResponse(actions).onSuccess();
          navigate(`/wms/package-exchange/swap-packages/${payload.id}`);
        },
      },
    );
  };

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Wymiana paczek
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="mx-3 mb-4 mt-2 ">
              <Typography fontSize="16" fontWeight="600" className="mb-3">
                Chcę przypisać paczki do zamówienia:
              </Typography>
              <Search.Form
                fetcherFn={getOrdersQuery}
                menuProps={{
                  normalizeMenuItem: listOrder => ({
                    text: `${listOrder.signature} ${listOrder.name && `(${listOrder.name})`}`,
                    value: String(listOrder.id),
                  }),
                }}
                isNullable={false}
                name="baseOrderId"
                textFieldProps={{ placeholder: "Szukaj zamówienia" }}
              />
            </div>
            <div className="mx-3 mb-4 mt-2">
              <div className="d-flex align-items-baseline gap-1">
                <Typography fontSize="16" fontWeight="400" className="mb-3">
                  z zamowienia
                </Typography>
                <Typography fontSize="12" fontWeight="600" className="italic">
                  (opcjonalnie)
                </Typography>
              </div>
              <Search.Form
                fetcherFn={getOrdersQuery}
                menuProps={{
                  normalizeMenuItem: listOrder => ({
                    text: `${listOrder.signature} ${listOrder.name && `(${listOrder.name})`}`,
                    value: String(listOrder.id),
                  }),
                }}
                isNullable
                name="sourceOrdersIds"
                textFieldProps={{ placeholder: "Szukaj zamówienia" }}
              />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Gotowe
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
